import type {NextPage} from 'next'
import React, { useEffect } from "react";

const IndexPage: NextPage = () => {
 /* useEffect(()=>{
    window.location.href = '/kol/dashboard'
  }, [])*/
  return (<>1111</>)
}

export default IndexPage

/*export async function getServerSideProps() {
  return {
    redirect: {
      permanent: false,
      destination: '/'
    }
  }
}*/
